export const API_URL = "https://apothekeadmin.24livehost.com/api";   //demo
export const FRONT_URL = "https://apotheke.24livehost.com";   //demo
// export const API_URL = 'https://admin.pharmapremium.de/api';  //live
// export const FRONT_URL = "https://pharmapremium.de";  //live

// Sandbox Paypal
/*export const paypalDetails = {
  clientId: "U2FsdGVkX1+/eYhRmPYwdmB5ApL6+bQUjv56Nnp028uLmG/2C1lSM5WyLK+zgrYhfBrmPyuISDz5psv3U+AFDUeD90vx+lFGdnIqM5BdLn4O1GjqX/f4nmqFuLd2MmQnB7FL4Pu+KvwngE1STgyLWA==",
  clientSecret: "U2FsdGVkX1/CflLaAC6ov36n/gxJ7cH11Sve8m8eMYVJnktZH1vlhByLuZTg45xfo3jqrIb/bYCEmTBVWmX6fw+kR9kQBynkKfUKHwL4hnSU80jUkO8PUnbOcVu1QR1eDgo35AsFORGPb0im9LnCeA==",
  baseUrl: 'https://api-m.sandbox.paypal.com'  
};*/

// Live Paypal
export const paypalDetails = {
  clientId: "U2FsdGVkX188jjez5SqWBa++UqV+NSpY6xCIALFmwGTUClsRsflpBmFkgja+Ml4Et9qSSefy9AjzBKakNB1RroAN3De9os5kcxqqF92kVyF2pxEERYA7JfKwbH4OMzJhlbnHca3VnhHyvr3tG2O/Ig==",
  clientSecret: "U2FsdGVkX18gQpk6tuty1jPA6L2GQZnzk3BVHL5IzyL+AZ9xSBcfNS+nrhOd6muzzuhwtlUECIWkpHckaBH69aaMciqHJxMNnGqiXrsBg7O086n6W4pBnRIrDNR5O3+lH4/kOZXEixt2OUSnQFvAlQ==",
  baseUrl: 'https://api-m.paypal.com'  
};

//developer account
/*export const paypalDetails = {
  clientId: "U2FsdGVkX18hfPDnE4KNqksqnI24+JkZpwg/Qovt/mynFKRlWQki1Ho1e/oWHYfC4nizHW2gf4JebZspOEe/SIoHvrt/an9tDKDxYuYazhqEvI7PbSH7Kj+vvi0SE7W7rSc7kYA8UWdsn/PtapC5sQ==",
  clientSecret: "U2FsdGVkX1+TlQEzhj1u1SeSP7ZTEYkYiwYUesEWajN5mEZe9su2lGEJ/qxiDSylQGQAiG2iIs8CiXi/wh6AsxwrDLGvATrR8GvTeX3Ob++Uwmwba8Zyb4NRm5VfNT3/OLa+nCYPQqMngfHP/Yx8mg==",
  baseUrl: 'https://api-m.sandbox.paypal.com'
};*/