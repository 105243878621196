import {
  Box,
  Container,
  CssBaseline,
  Grid,
  Typography,
  Button,
  Radio,
  FormControlLabel,
  TextField,
  CircularProgress,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Footer from "../../../src/components/Common/Footer";
import Header from "../../../src/components/Common/Header";
import OfferNotificationBar from "../../../src/components/Common/OfferNotificationBar";
import Sidebar from "./Sidebar";
import { Icon } from "@iconify/react";
import { styled } from "@mui/material/styles";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import {
  addAddress,
  listAddress,
  editAddress,
  deleteAddress,
  defaultAddress,
  userDetail,
} from "../../features/user/userSlice";
import ConfirmDialogWithYesNoButton from "../Common/ConfirmDialogWithYesNoButton";

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  "& > :not(style) ~ :not(style)": {
    marginTop: theme.spacing(2),
  },
}));

const UserAddress = () => {
  const { t } = useTranslation();
  const [showAddForm, setShowAddForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [addressId, setAddressId] = useState('');
  const dispatch = useDispatch();
  const { status, address, message, userData } = useSelector(
    (state) => state.user
  );
  const [selectedValue, setSelectedValue] = useState(
    userData?.data?.default_address?.id
      ? userData?.data?.default_address?.id
      : ""
  );

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleChange = async (event) => {
    setSelectedValue(event.target.value);
    try {
      await dispatch(
        defaultAddress({ address_id: event.target.value })
      ).unwrap();
      dispatch(userDetail());
    } catch (error) {}
  };

  const showAddAddressForm = () => {
    setShowAddForm(true);
  };

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const fetchData = async () => {
    try {
      dispatch(listAddress()).unwrap();
    } catch (error) {}
  };

  useEffect(() => {
    fetchData();
    setSelectedValue(
      userData?.data?.default_address?.id
        ? userData?.data?.default_address?.id
        : ""
    );
  }, [dispatch]);

  useEffect(() => {
    console.log(message,'message', status)
    if (status === "succeeded" && message !== "" && message !== null && message !== undefined) {
      toast.success(message);
      setShowAddForm(false);
      setAddressId("");
      fetchData();
      reset();
    }
  }, [message]);

  const handleClick = (address, action) => {
    setAddressId(address?.id);
    if (action === "edit") {
      setValue("first_name", address?.first_name);
      setValue("last_name", address?.last_name);
      setValue("house_no", address?.house_no);
      setValue("postal_code", address?.postal_code);
      setValue("address", address?.address);
      setValue("phone", address?.phone);
      setValue("city", address?.city);
      setShowAddForm(true);
    } else {
      setOpenConfirmDialog(true);
    }
  };

  const onSubmit = async (data) => {
    try {
      setLoading(true)
      console.log(addressId,'addressId')
      if (addressId !== "" && addressId !== undefined && addressId !== null) {
        const params = {
          ...data,
          address_id: addressId,
        };
        await dispatch(editAddress(params)).unwrap();
        setLoading(false)
      } else {
        await dispatch(addAddress(data)).unwrap();
        setLoading(false)
      }
    } catch (error) {
      toast.error(t("Something went wrong. Please try again."));
      setLoading(false)
    }
  };

  const handleCloseConfirmDialog = async (isApproved) => {
    setOpenConfirmDialog(false);
    if (isApproved === "yes") {
      if (addressId !== "" && addressId !== undefined && addressId !== null) {
        await dispatch(deleteAddress({ address_id: addressId })).unwrap();
      }
    } else {
      setAddressId("");
    }
  };

  return (
    <>
      <Header />
      <OfferNotificationBar />
      <section style={{ background: "#f6faff" }}>
        <Container className="dashboard_wrap">
          <CssBaseline />
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            <Sidebar />
            <Box className="dashboard_content">
              <Typography variant="h1">{t("Your addresses")}</Typography>
              {address?.data?.length > 0 &&
                address?.data?.map((add, index) => (
                  <Grid className="address_box" container key={`index${index}`}>
                    <Grid item xs={12} sm={12} md={6}>
                      <Typography>
                        {`${add?.first_name} ${add?.last_name}`}
                      </Typography>
                      <Typography>
                        {`${add?.house_no} ${add?.address}`}
                      </Typography>
                      <Typography>{`${add?.postal_code}`}</Typography>
                      <Typography>{`${add?.city}`}</Typography>
                    </Grid>
                    <Grid
                      className="address_action_btns"
                      item
                      xs={12}
                      sm={12}
                      md={6}
                    >
                      <Button onClick={() => handleClick(add, "edit")}>
                        <Icon icon="ic:baseline-mode-edit-outline" />{" "}
                        {t("Edit")}
                      </Button>
                      <Button onClick={() => handleClick(add, "delete")}>
                        <Icon icon="material-symbols:delete-outline" />{" "}
                        {t("Delete")}
                      </Button>
                      <FormControlLabel
                        control={
                          <Radio
                            checked={selectedValue == add?.id ? true : false}
                            value={add?.id}
                            onChange={handleChange}
                          />
                        }
                        label={`${t("Set as primary address")}`}
                      />
                    </Grid>
                  </Grid>
                ))}
              <Root className="moreaddress_form_btn">
                <Button onClick={showAddAddressForm}>
                  <svg viewBox="0 0 1012 40" fill="none">
                    <line
                      x1="-1.31134e-07"
                      y1="7.49997"
                      x2="477"
                      y2="7.49993"
                      stroke="#103F83"
                      stroke-width="3"
                    ></line>
                    <line
                      x1="535"
                      y1="7.49997"
                      x2="1012"
                      y2="7.49993"
                      stroke="#103F83"
                      stroke-width="3"
                    ></line>
                    <path
                      d="M502 2V16"
                      stroke="#103F83"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M495 9H509"
                      stroke="#103F83"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </Button>
              </Root>
              {showAddForm && (
                <Box
                  className="common_form"
                  component="form"
                  noValidate
                  autoComplete="off"
                  sx={{ width: "100%" }}
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Grid
                    className="address_addedit_form"
                    container
                    spacing={2.5}
                  >
                    <Grid item xs={12} sm={12} md={6}>
                      <Controller
                        name="first_name"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: t("First name is required"),
                        }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label={t("First name")}
                            fullWidth
                            margin="normal"
                            required
                            error={!!errors.first_name}
                            helperText={errors.first_name?.message}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <Controller
                        name="last_name"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: t("Last name is required"),
                        }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label={t("Last name")}
                            fullWidth
                            margin="normal"
                            required
                            error={!!errors.last_name}
                            helperText={errors.last_name?.message}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={8}>
                      <Controller
                        name="address"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: t("Address is required"),
                        }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label={t("Address")}
                            fullWidth
                            margin="normal"
                            required
                            error={!!errors.address}
                            helperText={errors.address?.message}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <Controller
                        name="house_no"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: t("House Number is required"),
                        }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label={t("House Number")}
                            fullWidth
                            margin="normal"
                            required
                            error={!!errors.house_no}
                            helperText={errors.house_no?.message}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <Controller
                        name="postal_code"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: t("Postal code is required"),
                        }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label={t("Postal Code")}
                            fullWidth
                            margin="normal"
                            required
                            error={!!errors.postal_code}
                            helperText={errors.postal_code?.message}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <Controller
                        name="city"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: t("City is required"),
                        }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label={t("City")}
                            fullWidth
                            margin="normal"
                            required
                            error={!!errors.city}
                            helperText={errors.city?.message}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <Controller
                        name="phone"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: t("Telephone is required"),
                        }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label={t("Telephone")}
                            fullWidth
                            margin="normal"
                            required
                            error={!!errors.phone}
                            helperText={errors.phone?.message}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        className="button1"
                        variant="contained"
                        size="large"
                        type="submit"
                        disabled={loading}
                      >
                        {loading ? (
                          <CircularProgress size={24} />
                        ) : (
                          t("Save Address")
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Box>
          </Box>
          {openConfirmDialog && (
            <ConfirmDialogWithYesNoButton
              openDialogAlert={openConfirmDialog}
              onCloseDialogAlert={handleCloseConfirmDialog}
              rowData={""}
              confirmMessage={"Are you sure you want to delete this address?"}
              confirmButtonText="Yes"
              declineButtonText="No"
            />
          )}
        </Container>
      </section>
      <Footer />
    </>
  );
};

export default UserAddress;
