import { Icon } from "@iconify/react";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Translations from "./Translations";

function Menu(props) {
  const { open, handleClose } = props;
  const user = useSelector((state) => state.user);
  const navigate = useNavigate()
  const { categories } = useSelector((state) => state?.common);

  const toggleClass = (id) => {
    const elem = document.getElementById(id);
    elem.classList.toggle("showSubMenu");
  };

  const handleClick = (e, url) => {
    e.preventDefault()
    navigate(url)
    handleClose(false)
  }

  return (
    <Drawer
      className="menuDrawer_outer"
      open={open}
      onClose={() => handleClose(false)}
    >
      <Box className="menuDrawer" role="presentation">
        <Box
          sx={{
            justifyContent: "space-between",
            display: "flex",
            alignItems: "flex-start",
          }}
        >
          <Typography>
            <a href="/">
              <img
                src="/images/apotheke-premium-logo.webp"
                alt=""
                width={200} height={80}
              />
            </a>
          </Typography>
          <IconButton
            className="menuCloseBtn"
            onClick={() => handleClose(false)}
          >
            <Icon icon="ic:sharp-close" />
          </IconButton>
        </Box>
        <List sx={{ p: "1rem 0px 0px 10px" }}>
          {categories?.data?.map((category, index) => (
            <Fragment key={`menu${index}`}>
              <ListItem disablePadding sx={{ display: "block" }}>
                {category?.children?.length > 0 ? (
                  <>
                    <ListItemButton
                      sx={{ padding: "0", backgroundColor: "#fff !important" }}
                    >
                      <Link className="w100 dflex">
                        <ListItemText
                          className="menuTxt"
                          onClick={() => {
                            toggleClass(category?.id);
                          }}
                          primary={category?.name}
                          sx={{ margin: "0", color: "#0f3f83" }}
                        />
                        <ListItemIcon
                          className="menuPlusIcon"
                          onClick={() => {
                            toggleClass(category?.id);
                          }}
                        >
                          <Icon icon="mdi:plus" fontSize={30} color="#0f3f83" />
                        </ListItemIcon>
                      </Link>
                    </ListItemButton>
                    <List id={category?.id}>
                      {category?.children?.map((subCat, index) => (
                        <ListItem key={`subCat${index}`}>
                          <Link
                            onClick={(e)=>handleClick(e,`/product-category/${category?.slug}/${subCat?.slug}`)}
                          >
                            <Icon icon="weui:arrow-outlined" fontSize={24} />
                            {subCat?.name}
                          </Link>
                        </ListItem>
                      ))}
                    </List>
                  </>
                ) : (
                  <ListItemButton
                    sx={{ padding: "0", backgroundColor: "#fff !important" }}
                  >
                    <Link onClick={(e)=>handleClick(e,`/product-category/${category?.slug}/${category?.id}`)} className="w100 dflex">
                      <ListItemText
                        className="menuTxt"
                       
                        primary={category?.name}
                        sx={{ margin: "0", color: "#0f3f83" }}
                      />
                    </Link>
                  </ListItemButton>
                )}
              </ListItem>
              <Divider />
            </Fragment>
          ))}
        </List>
        {/* <AccountLink user={user} /> */}
      </Box>
    </Drawer>
  );
}

const AccountLink = ({ user }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', padding: '20px 16px 8px', borderRadius: '4px', transition: 'background-color 0.3s', '&:hover': { backgroundColor: '#f0f0f0' } }}>
      <Link
        to={user?.first_name !== undefined ? "/my-account/dashboard" : "/my-account"}
        sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: '#1C407F' }}
      >
        <Typography variant="body1" sx={{ marginLeft: '8px' }}>
          <svg width="22" height="27" viewBox="0 0 22 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.820116 26.4307H0.813946C0.474601 26.4307 0.203125 26.1469 0.203125 25.8075C0.264824 21.612 3.68295 17.1264 11.0375 17.1264H11.1671C16.2881 17.1635 21.7978 19.8844 21.7978 25.7705C21.7978 26.1098 21.5202 26.3875 21.1808 26.3875C20.8415 26.3875 20.5638 26.1098 20.5638 25.7705C20.5638 20.7235 15.6896 18.3913 11.1609 18.3604C6.53345 18.3049 1.51732 20.6495 1.43711 25.826C1.43711 26.1654 1.15946 26.4368 0.820116 26.4368V26.4307Z" fill="#1C407F" />
            <path d="M11.0004 14.9608C6.8604 14.9608 3.4978 11.7031 3.4978 7.69265C3.4978 3.68221 6.8604 0.430664 11.0004 0.430664C15.1404 0.430664 18.503 3.68838 18.503 7.69882C18.503 11.7093 15.1343 14.967 11.0004 14.967V14.9608ZM11.0004 1.66465C7.54526 1.66465 4.73178 4.36707 4.73178 7.69882C4.73178 11.0306 7.54526 13.733 11.0004 13.733C14.4556 13.733 17.269 11.0244 17.269 7.69882C17.269 4.37324 14.4556 1.66465 11.0004 1.66465Z" fill="#1C407F" />
          </svg>&nbsp;&nbsp;&nbsp;
          <Translations text="Account" />
        </Typography>
      </Link>
    </Box>
  );
};

export default Menu;
