import { Icon } from "@iconify/react";
import {
  // Avatar,
  Container,
  // Divider,
  Grid,
  List,
  ListItem,
  // ListItemAvatar,
  ListItemText,
  Typography,
  ListItemIcon,
  Button,
  Box,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Rating from "@mui/material/Rating";
import "keen-slider/keen-slider.min.css";
import { useKeenSlider } from "keen-slider/react";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
// import { bestSellerProductList } from "../features/common/commonSlice";
import {useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom"; //{ NavLink, Link }
import Footer from "../../src/components/Common/Footer";
import Header from "../../src/components/Common/Header";
import KpiBoxes from "../../src/components/Common/KpiBoxes";
import SliderBoxes from "../../src/components/Common/SliderBoxes";
import OfferNotificationBar from "../../src/components/Common/OfferNotificationBar";
import Translations from "../components/Common/Translations";
// import HomeProductItem from "../../src/components/Products/HomeProductItem";
import { Link as ScrollLink } from 'react-scroll';
import { getInitalsFromName, encryptData } from "../utils/util";

const reviews = [
  {
    id: 1,
    text: "Care Communications Solutions ist zu meinem vertrauenswürdigen Gesundheitspartner geworden. Ihre Website ist benutzerfreundlich und bietet eine umfangreiche Auswahl an Medikamenten, sowohl verschreibungspflichtig als auch rezeptfrei. Was sie auszeichnet, ist ihr unerschütterliches Engagement für Qualität und Sicherheit, um sicherzustellen, dass alle Produkte EU-zugelassen sind und von seriösen Herstellern bezogen werden.",
    name: "Herbert Müller",
  },
  {
    id: 2,
    text: "Die Bestellung ist ein Kinderspiel, und ihre lizenzierten Apotheker garantieren eine genaue Rezeptabwicklung. Die wettbewerbsfähigen Preise machen Gesundheitsversorgung erschwinglicher, und ihr diskreter, pünktlicher Versand ist ein großer Pluspunkt. Ich schätze die Privatsphäre, die ihre diskrete Verpackung bietet.",
    name: "Erika Schmidt",
  },
  {
    id: 3,
    text: "Der Kundensupport von Care Communications Solutions ist erstklassig; sie sind immer ansprechbar und hilfsbereit. Diese Online-Apotheke setzt wirklich auf Kundenzufriedenheit und bietet eine nahtlose und zuverlässige Gesundheitserfahrung.",
    name: "Klaus Fischer",
  },
  {
    id: 4,
    text: "Für Bequemlichkeit, Erschwinglichkeit und ein beruhigendes Gefühl kann ich Care Communications Solutions wärmstens empfehlen. Egal, ob Sie Rezepte oder Wellnessprodukte benötigen, sie liefern konsequent Exzellenz in jeder Service-Hinsicht.",
    name: "Anna Wagner",
  },
  {
    id: 5,
    text: "Care Communications Solutions bietet eine nahtlose Online-Apothekenerfahrung. Ihre umfangreiche Auswahl an hochwertigen Medikamenten und Gesundheitsprodukten stellt sicher, dass Sie finden, was Sie brauchen. Mit sicheren Transaktionen und diskreter Verpackung steht Ihre Privatsphäre an erster Stelle. Expertenapotheker stehen für persönliche Beratung zur Verfügung, und schneller Versand garantiert, dass Sie Ihre Essentials rechtzeitig erhalten. Vertrauen Sie Care Communications Solutions für bequeme, zuverlässige Gesundheitslösungen.",
    name: "Markus Weber",
  },
  {
    id: 6,
    text: "Ich empfehle Care Communications Solutions wärmstens! Ihre umfangreiche Palette an pharmazeutischen Produkten, schnelle Lieferung und außergewöhnlicher Kundenservice machen sie zu meiner ersten Wahl für all meine Gesundheitsbedürfnisse. Vertrauenswürdig und zuverlässig, setzt Care Communications Solutions den Standard für Online-Apotheken.",
    name: "Sophie Müller",
  },
  {
    id: 7,
    text: "Leider habe ich eine peinliche medizinische Erkrankung, und ich habe meine reguläre Apotheke benutzt. Care Communications Solutions war die dritte Online-Apotheke, die ich ausprobiert habe, und ich bleibe bei ihnen. Die Schnelligkeit des Service und der Preis sind unschlagbar.",
    name: "Maximilian Lehmann",
  },
  {
    id: 8,
    text: "Meine Mutter ist älter, und deshalb kümmere ich mich um alle Medikamente für sie. Ich habe einen Vollzeitjob, und dieser Online-Apotheken-Premiumdienst hat mein Leben so viel einfacher gemacht.",
    name: "Helga Schneider",
  },
  {
    id: 9,
    text: "Normalerweise schreibe ich keine Bewertungen, aber ich habe ein so professionelles Niveau bei der Bestellung von Rezepten und der Lieferung erlebt, dass ich es für wichtig halte, anderen Menschen mit einer Bewertung zu helfen.",
    name: "Thomas Becker",
  },
  {
    id: 10,
    text: "Ich dachte, Amazon wäre gut, aber Care Communications Solutions ist wie Amazon Prime mit diskretem Express, was ich schätze.",
    name: "Carolin Zimmermann",
  },
];

function Home() {
  const { t } = useTranslation();
  const [loaded, setLoaded] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const dispatch = useDispatch();
  const { categories, faqs } = useSelector((state) => state?.common); //{ categories, faqs, bestSellerProduct }
  const [visibleReviews, setVisibleReviews] = useState(6);

  const [sliderRef, instanceRef] = useKeenSlider({
    breakpoints: {
      "(min-width: 300px)": {
        slides: { perView: 1, spacing: 10 },
      },
      "(min-width: 768px)": {
        slides: { perView: 2, spacing: 10 },
      },
      "(min-width: 992px)": {
        slides: { perView: 3, spacing: 10 },
      },
      "(min-width: 1200px)": {
        slides: { perView: 4, spacing: 10 },
      },
    },
    initial: 0,
    loop: true,
    mode: "snap",
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
  });
  //This block is related to the bestseller product and commented on client requirement

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       dispatch(bestSellerProductList()).unwrap();
  //     } catch (error) {}
  //   })();
  // }, [dispatch]);

  // const [productSliderRef, productInstanceRef] = useKeenSlider({
  //   breakpoints: {
  //     "(min-width: 300px)": {
  //       slides: { perView: 1, spacing: 10 },
  //     },
  //     "(min-width: 768px)": {
  //       slides: { perView: 2, spacing: 20 },
  //     },
  //     "(min-width: 992px)": {
  //       slides: { perView: 3, spacing: 20 },
  //     },
  //     "(min-width: 1200px)": {
  //       slides: { perView: 4, spacing: 24 },
  //     },
  //   },
  //   initial: 0,
  //   loop: true,
  //   mode: "snap",
  //   slideChanged(slider) {
  //     setCurrentSlide(slider.track.details.rel);
  //   },
  //   created() {
  //     setLoaded(true);
  //   },
  // });
  useEffect(() => {
    const images = document.querySelectorAll('img[data-src]');
    images.forEach(img => {
      img.src = img.getAttribute('data-src');
      img.onload = () => {
        img.removeAttribute('data-src');
      };
    });
  }, []);

  useEffect(()=>{
    document.body.classList.add("home-page");
    return () => {
      document.body.classList.remove("home-page");
    };
  },[])

  function Arrow(props) {
    const disabled = props.disabled ? " arrow--disabled" : "";
    return (
      <svg
        onClick={props.onClick}
        className={`arrow ${
          props.left ? "arrow--left" : "arrow--right"
        } ${disabled}`}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        {props.left && (
          <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
        )}
        {!props.left && (
          <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
        )}
      </svg>
    );
  }

  const loadMoreReviews = () => {
    setVisibleReviews((prev) => prev + 6);
  };

  return (
    <>
      <Header />
      <OfferNotificationBar />
      <section className="ndBanner">
        <Container maxWidth={"xl"}>
          <Grid container>
            <Grid xs={12} sm={12} md={6} className="ndBanner_content">
              <Typography
                variant="h1">
                <Translations text="Empower Your Health Journey with Pharma Premium's Trusted Care" />
              </Typography>
              <Typography variant="h4">
                <Translations text="One stop shop for your medications from the comfort of your home." />
              </Typography>
              <List>
                <ListItem>
                  <ListItemIcon sx={{ minWidth: "32px" }}>
                    <Icon
                      icon="material-symbols:check-circle-rounded"
                      style={{ color: "#1C407F", fontSize: "22px" }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={<><Translations text="Medication delivered " /><b><Translations text="safely and discreetly" /></b></>}
                    primaryTypographyProps={{
                      sx: { fontSize: "1.125rem" }
                    }}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon sx={{ minWidth: "32px" }}>
                    <Icon
                      icon="material-symbols:check-circle-rounded"
                      style={{ color: "#1C407F", fontSize: "22px" }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={<><Translations text="Obtain " /><b><Translations text="online prescription " /></b><Translations text="from as low as " /><b><Translations text="€10" /></b></>}
                    primaryTypographyProps={{
                      sx: { fontSize: "1.125rem" }
                    }}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon sx={{ minWidth: "32px" }}>
                    <Icon
                      icon="material-symbols:check-circle-rounded"
                      style={{ color: "#1C407F", fontSize: "22px" }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={<><Translations text="Get " /><b><Translations text="professional consultation " /></b><Translations text="within minutes" /></>}
                    primaryTypographyProps={{
                      sx: { fontSize: "1.125rem" }
                    }}
                  />
                </ListItem>
              </List>

              <ScrollLink className="button2"
                to="category-section"
                smooth={true}
                duration={500}
              >
                <Translations text="Shop All Products 👉" />
              </ScrollLink>

              <Box
                sx={{
                  mt: 2.5,
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 1.25, mb: 2 }}>
                  <Rating
                    className="rating_star"
                    value={4.5}
                    precision={0.5}
                    readOnly
                    sx={{ color: "#F0AE43", fontSize: "16px" }}
                  />
                  <Typography
                    sx={{
                      fontSize: "14px",
                    }}
                  >
                    <b>4.7/5 </b><Translations text="| Based on " /><b>3000+ </b><Translations text="Satisfied Customers" />
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <img
                    src="/images/graphics/arzneimittel-logo.png"
                    alt="German Medicine Certification"
                    width={99}
                    height={41}
                    loading="lazy"
                  />
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "600"
                    }}
                  >
                    <Translations text="German Approved Medicine" />
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid className="nbBanner_img_col" xs={12} sm={12} md={6}>
              <Box>
                <div className="nbBanner_img">
                  <img
                    src="/images/graphics/banner-img.webp"
                    alt="Healthcare illustration"
                    width={493}
                    height={671}
                  />
                </div>

                <ul>
                  <li><span>75%</span> <Translations text="Of patients have approved online prescriptions" /></li>
                  <li><span>45%</span> <Translations text="Germans prefer online consultation over physical visits" /></li>
                </ul>
                
              </Box>
            </Grid>
          </Grid>
        </Container>
      </section>
      <section className="shipping_wrap" style={{ background: "#1C407F" }}>
        <Container maxWidth={"xl"}>
          <Grid container>
            <Grid className="shipping_block" item xs={12}>
              <KpiBoxes
                text={`${t("Wide range of medication available")}`}
                icon="/images/graphics/painkiller_icon.svg"
              />
              <KpiBoxes
                text={`${t("Medicines approved by practitioners")}`}
                icon="/images/graphics/approved-icon.svg"
                size="2.4"
              />
              <KpiBoxes
                text={`${t("24/7 exceptional customer Support")}`}
                icon="/images/graphics/feedback_icon.svg"
                size="2.4"
              />
              <KpiBoxes
                text={`${t("Market Competitive Pricing")}`}
                icon="/images/graphics/coin_icon.svg"
                size="2.4"
              />
            </Grid>
          </Grid>
        </Container>
      </section>
      {/*{bestSellerProduct?.data?.length > 0 && (
        <section className="bestseller_prd">
          <Container maxWidth={"xl"}>
            <Grid item xs={12} sm={12} className="common_heading" sx={{ textAlign: "center", mb: "45px"}}>
              <Typography variant="h2" sx={{ mb: "18px" }}>
                <Translations text="Shop our Bestsellers" />
              </Typography>
              <Typography sx={{ fontSize: "18px" }}>
                <Translations text="Supercharge your health and performance" />
              </Typography>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <div className="navigation-wrapper" style={{ position: 'relative' }}>
                  <div ref={productSliderRef} className="keen-slider">
                    {bestSellerProduct?.data?.map((product, index) => (
                      <div
                        className="keen-slider__slide product_item"
                        key={product.id}
                      >
                        <HomeProductItem
                          product={product}
                          showBrand={true}
                          showPrice={true}
                          productClass="product_bx"
                          slug={product?.product_slug}
                          link={`${product?.product_slug}`}
                        />
                      </div>
                    ))}
                  </div>
                  {loaded && productInstanceRef.current && (
                    <>
                      <Arrow
                        left
                        onClick={(e) =>
                          e.stopPropagation() || productInstanceRef.current?.prev()
                        }
                        disabled={currentSlide === 0}
                      />
                      <Arrow
                        onClick={(e) =>
                          e.stopPropagation() || productInstanceRef.current?.next()
                        }
                        disabled={
                          currentSlide ===
                          productInstanceRef.current.track.details.slides.length - 1
                        }
                      />
                    </>
                  )}
                </div>
              </Grid>
            </Grid>
          </Container>
        </section>
      )}*/}
      <section className="gettoKnow_wrap">
        <Container maxWidth={"xl"}>
          <Grid container className="gettoKnow_inner">
            <Grid className="gettoKnow_img" xs={12} sm={12} md={6}>
              <img
                src="/images/graphics/female-doctor-img.webp"
                alt=""
                width="896"
                height="1017"
                loading="lazy"
              />
            </Grid>
            <Grid xs={12} sm={12} md={6} className="gettoKnow_content">
              <Typography variant="h2">
                <Translations text="Get to know" /> <br></br> <Translations text="pharma premium" />
              </Typography>
              <Typography variant="h5">
                <Translations text="An online service platform you can trust for all your medical needs" />
              </Typography>
              <Typography>
                <>
                <Translations text="We " /><b><Translations text="connect customers " /></b><Translations text="with regulated EU pharmacies that only dispense legal EU medicines (German medicines)." />
                </>
              </Typography>
              <Typography>
                <>
                <Translations text="We " />
                <b><Translations text="offer an online prescription service " /></b>
                <Translations text="that works with EU doctors who review your medical questionnaire and issue an electronic prescription to EU pharmacies within minutes." />
                </>
              </Typography>
              <Box className="annaWagner_bx">
                <Box style={{ display: "flex", alignItems: "center", gap: "10px"}}>
                  <img src="/images/graphics/anna-wagner.png" alt="" width={40} height={40} />
                  <Box>
                    <Typography variant="h6" sx={{ fontSize: "14px", fontWeight: "600" }}>Anna Wagner</Typography>
                    <Rating
                      className="rating_star"
                      value={5}
                      precision={0.5}
                      readOnly
                      sx={{ fontSize: "18px", color: "#F0AE43"}}
                    />
                  </Box>
                </Box>
                <Typography>
                  <Translations text="“For convenience, affordability, and peace of mind, I highly recommend Care Communications Solutions. Whether you need prescriptions or wellness products, they consistently deliver excellence in every aspect of service.”" />
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </section>
      <section className="simple_steps" style={{ background: "#e1f0fe" }}>
        <Container maxWidth={"xl"}>
          <Grid item xs={12} sm={12} className="common_heading" sx={{ textAlign: "center", mb: "45px" }}>
            <Typography variant="h2" sx={{ mb: "18px" }}>
              <Translations text="get your health sorted in 3 simple steps" />
            </Typography>
            <Typography sx={{ fontSize: "18px" }}>
              <Translations text="It's as simple as 1, 2, 3..." />
            </Typography>
          </Grid>
          <Grid>
            <Grid container className="simple_steps_inner">
              <Grid item xs={12} sm={4} className="simple_steps_bx">
                <SliderBoxes
                  title={`${t("Choose Medication")}`}
                  text={`${t("Choose your medication from our selection and answer a few quick questions to make sure it is the right choice for you.")}`}
                  icon={"/images/graphics/steps-img1.webp"}
                  number={1}
                  width={338}
                  height={338}
                />
              </Grid>
              <Grid item xs={12} sm={4} className="simple_steps_bx">
                <SliderBoxes
                  title={`${t("Prescription Approval")}`}
                  text={`${t("Your questionnaire will be carefully reviewed by an experienced, licensed doctor and pharmacist.")}`}
                  icon={"/images/graphics/steps-img2.webp"}
                  number={2}
                  width={338}
                  height={338}
                />
              </Grid>
              <Grid item xs={12} sm={4} className="simple_steps_bx">
                <SliderBoxes
                  title={`${t("Take charge of your health")}`}
                  text={`${t("Receive your medication the very next day, ensuring to get the supply to keep you going.")}`}
                  icon={"/images/graphics/steps-img3.webp"}
                  number={3}
                  width={338}
                  height={338}
                />
              </Grid>
            </Grid>
            <Box className="simple_steps_btn">
              <ScrollLink
                to="category-section"
                smooth={true}
                duration={500}
                className="button2">
                <Translations text="Shop All Products 👉" />
              </ScrollLink>
            </Box>
            <Box className="ssRatings" sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 1.25, textAlign: "center", mt: 2 }}>
              <Rating
                className="rating_star"
                value={4.5}
                precision={0.5}
                readOnly
                sx={{ color: "#F0AE43", fontSize: "16px" }}
              />
              <Typography
                sx={{
                  fontSize: "14px",
                }}
              >
                <b>4.7/5 </b><Translations text="| Based on " /><b>3000+ </b><Translations text="Satisfied Customers" />
              </Typography>
            </Box>
          </Grid>
        </Container>
      </section>
      {categories?.data?.length > 0 && (
        <section id="category-section" className="category_wrap">
          <Container maxWidth={"xl"}>
            <Grid className="common_heading" item xs={12} sm={12} sx={{ mb: "45px" }}>
              <Typography variant="h2" sx={{ mb: "18px" }}>
                <Translations text="explore our wide range of categories" />
              </Typography>
              <Typography sx={{ fontSize: "18px" }}>
                <Translations text="Shop full range of products" />
              </Typography>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <div className="navigation-wrapper" style={{ position: 'relative' }}>
                  <div ref={sliderRef} className="keen-slider">
                    {categories?.data?.map((category, index) => (
                      <div
                        className="keen-slider__slide"
                        key={`category-slide-${index}`}
                      >
                        <SliderBoxes
                          title={category?.name}
                          text={"Jetzt kaufen"}
                          width={318}
                          height={423}
                          icon={category?.banner_image || "/images/top-icon2.svg"}
                          size="12"
                          link={
                            category?.children?.length > 0
                              ? `/product-category/${category?.slug}`
                              : `/product-category/${category?.slug}/${category?.id}`
                          }
                        />
                      </div>
                    ))}
                  </div>
                  {loaded && instanceRef.current && (
                    <>
                      <Arrow
                        left
                        onClick={(e) =>
                          e.stopPropagation() || instanceRef.current?.prev()
                        }
                        disabled={currentSlide === 0}
                      />

                      <Arrow
                        onClick={(e) =>
                          e.stopPropagation() || instanceRef.current?.next()
                        }
                        disabled={
                          currentSlide ===
                          instanceRef.current.track.details.slides.length - 1
                        }
                      />
                    </>
                  )}
                  {/* {loaded && instanceRef.current && (
                    <div className="ksDots">
                      {[
                        ...Array(
                          instanceRef.current.track.details.slides.length
                        ).keys(),
                      ].map((idx) => {
                        return (
                          <button
                            key={idx}
                            onClick={() => {
                              instanceRef.current?.moveToIdx(idx);
                            }}
                            className={
                              "ksDot" + (currentSlide === idx ? " active" : "")
                            }
                          ></button>
                        );
                      })}
                    </div>
                  )} */}
                </div>
              </Grid>
            </Grid>
          </Container>
        </section>
      )}
      {/* <section className="consultDoctor_wrap" style={{ backgroundImage: "url('/images/graphics/consult-doctor-img.jpg')" }}>
        <Container maxWidth={"xl"}>
          <Grid container>
            <Grid xs={12} sm={12} md={5}>
              <Typography variant="h2">
                <Translations text="need to consult a doctor?" />
              </Typography>
              <Typography variant="h4">
                <Translations text="Get appointment for online consultation from our licensed European doctor for €35" />
              </Typography>
              <List>
                {[
                  "Professional consultation from the comfort of your home",
                  "No risk of infections from the doctor's clinic",
                  "No need to spend a fortune on doctor's visit"
                ].map((text, index) => (
                  <ListItem
                    key={index} >
                    <ListItemIcon sx={{ minWidth: "32px" }}>
                      <Icon
                        icon="material-symbols:check-circle-rounded"
                        style={{ color: "#fff", fontSize: "22px" }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={<Translations text={text} />}
                      primaryTypographyProps={{
                        sx: { fontSize: "1.125rem" }
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
        </Container>
      </section> */}
      <section className="reviews_wrap">
        <Container maxWidth={"xl"}>
          <Box className="reviews_header">
            <Typography
              variant="h2">
              <Translations text="check out what people are saying about us" />
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 1.25, mb: 3 }}>
              <Rating
                value={4.5}
                precision={0.5}
                readOnly
                sx={{ color: "#F0AE43", fontSize: "28px" }}
              />
              <Typography
                sx={{
                  fontSize: "22px",
                  color: "#1C407F",
                  fontWeight: "400"
                }}
              >
                <b>4.7/5 </b><Translations text="| Based on " /><b>3000+ </b><Translations text="Satisfied Customers" />
              </Typography>
            </Box>
          </Box>
          <Grid className="reviews_outer">
            {reviews.slice(0, visibleReviews).map((review) => (
              <Grid key={review.id} className="reviews_bx">
                {/* <div className={`keen-slider__slide number-slide${review.id}`}> */}
                  <Rating value="5" readOnly style={{ fontSize: "20px", color: "#F0AE43" }} />
                  <Typography style={{ fontSize: "17px"}}>{review.text}</Typography>
                  <Box sx={{ mt: "10px", display: "flex", alignItems: "center", gap: "15px" }}>
                    <span style={{ background: "#1C407F2B", width: "46px", height: "46px", display: "flex", alignItems: "center", justifyContent: "center", fontSize: "11px", fontWeight: "600", borderRadius: "30px", textTransform: "uppercase" }}>{getInitalsFromName(review.name)}</span>
                    <Box>
                    <Typography variant="h6" style={{ fontSize: "11px", fontWeight: "600" }}>{review.name}</Typography>
                    <Typography className="verifiedCustomer" style={{ fontSize: "11px", display: "flex", alignItems: "center", gap: "5px" }}>
                    <Icon icon="material-symbols:check-circle-rounded" style={{ color: "#1C407F" }}/> Verified Customer
                    </Typography>
                    </Box>
                  </Box>
                {/* </div> */}
              </Grid>
            ))}
          </Grid>
          {visibleReviews < reviews.length && (
              <Grid item xs={12} className="loadMore_btn">
                <Button
                  variant="contained"
                  onClick={loadMoreReviews}
                >
                  <Translations text="Load More Reviews" />
                </Button>
              </Grid>
            )}
        </Container>
      </section>
      {faqs?.data?.length > 0 && (
        <section className="faq_home" style={{ background: "#E1F0FE" }}>
          <Container maxWidth={"xl"}>
            <Grid item xs={12} sm={12} className="common_heading" sx={{ textAlign: "center" }}>
              <Typography variant="h2" sx={{ mb: "18px" }}>
                <Translations text="frequently asked questions" />
              </Typography>
              <Typography sx={{ fontSize: "18px" }}>
                <Translations text="Need more help? We are here for you." />
              </Typography>
            </Grid>
            <Grid container className="faq_accordion">
              {faqs?.data?.map((faq, index) => (
                <>
                  {index < 6 && (
                    <Grid
                      key={`faq${index}`}
                      item
                      className="faq_home_items"
                    >
                      <Accordion className="faqHome_innerItem" sx={{ background: "#F0F7FD", boxShadow: "none", borderRadius: "10px !important" }}>
                        <AccordionSummary
                          sx={{ padding: "0" }}
                          expandIcon={
                            <Icon
                              color="#FBAB1B"
                              fontSize={30}
                              icon="ri:arrow-down-s-line"
                              style={{ background: "#fff", borderRadius: "15px", padding: "5px"}}
                            />
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography
                            variant="h5">
                            {faq?.question}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails className="faqhome_content">
                          <Typography
                            dangerouslySetInnerHTML={{ __html: faq?.answer }}
                          ></Typography>
                        </AccordionDetails>
                      </Accordion>
                      {/* <Divider sx={{ borderColor: "#0f3f83" }} /> */}
                    </Grid>
                  )}
                </>
              ))}
            </Grid>
            <Box className="faqBtn" sx={{ textAlign: "center" }}>
              <Link to="/frequently-asked-questions">
                <Translations text="View all frequent questions" />
              </Link>
            </Box>
          </Container>
        </section>
      )}
      <section className="dppt_wrap">
        <Container maxWidth={"xl"}>
          <Grid container className="dppt_inner">
            <Grid className="dppt_content" xs={12} sm={12} md={7}>
              <Typography variant="h2">
                <Translations text="Feel Better, Perform Better – Discover Pharma Premium Today!" />
              </Typography>
              <Typography variant="h4">
                <Translations text="So what are you waiting for? Click the button and get started today!" />
              </Typography>
              <ScrollLink
                to="category-section"
                smooth={true}
                duration={500}
                className="button2"
              >
                <Translations text="Shop All Products 👉" />
              </ScrollLink>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1.25, mt: 2 }}>
                  <Rating
                    value={4.5}
                    precision={0.5}
                    readOnly
                    sx={{ color: "#F0AE43", fontSize: "16px" }}
                  />
                  <Typography
                    sx={{
                      fontSize: "14px",
                    }}
                  >
                    <b>4.7/5 </b><Translations text="| Based on " /><b>3000+ </b><Translations text="Satisfied Customers" />
                  </Typography>
                </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={5} className="dppt_img">
              <img
                src="/images/graphics/PFYTBQ1.png"
                alt=""
                width="562"
                height="471"
                loading="lazy"
              />
            </Grid>
          </Grid>
        </Container>
      </section>
      <Footer />
    </>
  );
}

export default Home;
