import { Container, Grid, Typography, Breadcrumbs,Link } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { subCategoryList } from "../../features/common/commonSlice";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import KpiBoxes from "../Common/KpiBoxes";
import OfferNotificationBar from "../Common/OfferNotificationBar";
import { useParams } from "react-router-dom";
import { NavigateNext } from '@mui/icons-material';
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";

function Home() {
  let {slug} = useParams();
  const dispatch = useDispatch();
  const { subCategory } = useSelector((state) => state?.common);
  const {t} = useTranslation();

  useEffect(() => {
    (async () => {
      try {
        dispatch(subCategoryList(slug)).unwrap();
      } catch (error) {}
    })();
  }, [dispatch,slug]);

  return (
    <>
      <Header />
      <OfferNotificationBar />
      <section style={{ background: "#eef7fe", padding: "40px 0" }}>
        <Container maxWidth={"xl"}>
          <Typography
            className="faq_home_title"
            variant="h1"
            sx={{
              textAlign: "center",
              fontWeight: 700,
              mb: "1rem"
            }}
          >
            {subCategory?.data?.name}
          </Typography>
          <Breadcrumbs
            className="breadcrumbs"
            separator={<NavigateNext fontSize="small" />}
            aria-label="breadcrumb"
            sx={{ justifyContent: "center", display: "flex" }}
          >
            <Link underline="hover" color="inherit" href="/">
              <Icon icon="carbon:home" fontSize={18} />
            </Link>
            <Typography color="#0f3f83">{subCategory?.data?.name}</Typography>
          </Breadcrumbs>
          {subCategory?.data?.children?.length > 0 && (
            <Grid container className="home_services">
              {subCategory?.data?.children?.map((category, index) => {
                return (
                  <KpiBoxes
                    title={category?.name}
                    text={`(${category?.products_count}) ${t('Products')}`}
                    icon=""
                    size="4"
                    link={`/product-category/${subCategory?.data?.slug}/${category?.slug}?page=1`}
                    rightImage="/images/arrow.svg"
                    backgroundColor="#ffffff"
                    key={`category${index}`}
                  />
                );
              })}
            </Grid>
          )}
          <Typography color="#0f3f83">{subCategory?.data?.description}</Typography>
        </Container>
      </section>
      <Footer />
    </>
  );
}

export default Home;
