import { Icon } from "@iconify/react";
import {
  Box,
  Button,
  Menu,
  MenuItem,
  Toolbar,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Translations from "./Translations";

function HeaderMenu() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { categories } = useSelector((state) => state?.common);

  const [anchorEl, setAnchorEl] = useState(null);
  const [subMenuAnchorEl, setSubMenuAnchorEl] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const handleMenuOpen = (event) => {
    console.log(event.currentTarget,'event.currentTarget',Boolean(event.currentTarget))
    setAnchorEl(event.currentTarget);
    setSubMenuAnchorEl(null); // Close any open submenus when opening the main menu
    setSelectedCategory(null);
  };

  const handleMenuClose = () => {
    if (!subMenuAnchorEl) {
      setAnchorEl(null);
      setSelectedCategory(null);
    }
  };

  const handleSubMenuClose = () => {
    setSubMenuAnchorEl(null);
    setSelectedCategory(null);
  };

  const handleSubMenuOpen = (event, category) => {
    if (!category?.children?.length) {
      setSubMenuAnchorEl(null);
      setSelectedCategory(null);
      return;
    }
    
    // Close any existing submenu before opening a new one
    setSubMenuAnchorEl(null);
    setSelectedCategory(null);

    // Open the new submenu
    setSubMenuAnchorEl(event.currentTarget);
    setSelectedCategory(category);
  };

  const handleClick = (e, url) => {
    e.preventDefault();
    navigate(url); // Use navigate to redirect
    handleMenuClose();
  };

  return (
    <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
      {/* Navigation Menu */}
      <Box className="mainMenu_inner">
        {/* Shop All Menu */}
        <Button
          onMouseEnter={(e) => window.innerWidth > 1200 && handleMenuOpen(e)}
          onClick={(e) => window.innerWidth <= 1200 && handleMenuOpen(e)}
          onTouchStart={(e) => handleMenuOpen(e)}
          sx={{ textTransform: "uppercase", fontSize: "1rem", color: "#1C407F" }}
        >
          <Translations text="Shop All" />
          <Icon icon="mdi:chevron-down" fontSize={20} />
        </Button>
        <Menu className="categories"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          disableScrollLock
          MenuListProps={{
            onMouseLeave: () => {
              if (!subMenuAnchorEl) {
                handleMenuClose();
              }
            }
          }}
        >
          {categories?.data?.map((category) => (
            <MenuItem
              key={category?.slug} // Use category slug as the key
              onClick={(e) => {
                if (!category?.children?.length) {
                  handleClick(e,`/product-category/${category?.slug}/${category?.id}`); // Ensure the URL is correct
                } else {
                  handleSubMenuOpen(e, category);
                }
              }}
            >
              {category?.name}
              {category?.children?.length > 0 && (
                <Icon icon="mdi:chevron-right" fontSize={20} />
              )}
            </MenuItem>
          ))}
        </Menu>

        {/* Subcategories Menu */}
        <Menu className="subCategories"
          anchorEl={subMenuAnchorEl}
          open={Boolean(subMenuAnchorEl) && Boolean(anchorEl)}
          onClose={handleSubMenuClose}
          sx={{ marginLeft: "10px" }}
          MenuListProps={{
            onMouseLeave: handleSubMenuClose
          }}
        >
          {selectedCategory?.children?.map((subCat) => (
            <MenuItem
              key={subCat?.slug} // Use subcategory slug as the key
              onClick={(e) => handleClick(e, `/product-category/${selectedCategory?.slug}/${subCat?.slug}`)}
            >
              <Icon icon="weui:arrow-outlined" fontSize={18} />
              {subCat?.name}
            </MenuItem>
          ))}
        </Menu>
        <Link to="/frequently-asked-questions" alt={`${t("Faq")}`}>
          <Translations text="Faq" />
        </Link>
        <Link to="/blog" alt={`${t("Blog")}`}>
          <Translations text="Blog" />
        </Link>
        <Link to="/contact-us" alt={`${t("Contact us")}`}>
          <Translations text="Contact us" />
        </Link>
      </Box>
    </Toolbar>
  );
}

export default HeaderMenu;