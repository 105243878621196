import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Box, Button, CircularProgress, Grid, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import AuthBanner from '../../src/components/AuthBanner';
import Footer from '../../src/components/Common/Footer';
import Header from '../../src/components/Common/Header';
import OfferNotificationBar from '../../src/components/Common/OfferNotificationBar';
import { updatePassword } from '../features/auth/authSlice';


function ResetPassword() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useParams();
  // Split the string by '&'
  const [tokenPart, emailPart] = token.split('&');

  // Extract the token
  const token_str = tokenPart;

  // Extract the email from the second part
  const email = emailPart.split('=')[1];
  const { control, handleSubmit, watch, formState: { errors } } = useForm();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const password = watch('password', '');

  const onSubmit = async (data) => {
    try {
      setLoading(true)
      const { password, confirm_password } = data
      const response = await dispatch(updatePassword({ token : token_str, email, password, confirm_password })).unwrap();
      if (response?.status) {
        setLoading(false)
        toast.success(t('Reset Password successfully')); // Use translation for success message
        navigate('/my-account');
      } else {
        setLoading(false)
        toast.error(response?.message)
      }
    } catch (error) {
      setLoading(false)
      toast.error(error?.error)
    }
  };

  const handleClickShowPassword = () => setShowPassword((prev) => !prev);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((prev) => !prev);

  return (
    <>
      <Header />
      <OfferNotificationBar />
      <Grid container>
        <AuthBanner />
        <Grid item sm={12} md={6} bgcolor={"rgb(238, 247, 254)"} className='login_right'>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div className='fyp_txt'>
              <Typography variant="h6" gutterBottom>
                {t('Reset Password')} {/* Use translation for header */}
              </Typography>
              <Typography variant='p'>
                {t('Choose a new password for your account')} {/* Use translation for description */}
              </Typography>
            </div>

            <Box className='login_form_inner'
              component="form"
              noValidate
              autoComplete="off"
              sx={{ width: '100%' }}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Controller
                name="password"
                control={control}
                defaultValue=""
                rules={{ required: t('New password is required') }} // Use translation for validation message
                render={({ field }) => (
                  <TextField
                    {...field}
                    type={showPassword ? 'text' : 'password'}
                    label={t('Enter new password')} // Use translation for label
                    fullWidth
                    margin="normal"
                    required
                    error={!!errors.password}
                    helperText={errors.password?.message}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              <Controller
                name="confirm_password"
                control={control}
                defaultValue=""
                rules={{
                  required: t('Confirm password is required'),
                  validate: (value) => value === password || t('Passwords do not match')
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type={showConfirmPassword ? 'text' : 'password'}
                    label={t('Repeat new password')}
                    fullWidth
                    margin="normal"
                    required
                    error={!!errors.confirm_password}
                    helperText={errors.confirm_password?.message}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowConfirmPassword}
                            edge="end"
                          >
                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              <Button className='button1'
                variant="contained"
                fullWidth
                type="submit"
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : t('Save new password')} {/* Use translation for button text */}
              </Button>
              <div className='forgotpassword'>
                <Link to="/my-account">
                  {t('Back to Login')} {/* Use translation for link text */}
                </Link>
              </div>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Footer />
    </>
  );
}

export default ResetPassword;
