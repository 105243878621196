import React from "react";
import { Grid, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";

function KpiBoxes(props) {
  const { text, icon, size, rightImage, link, backgroundColor, title } = props;

  return (
    <Grid item xs={12} md={size} sx={{ backgroundColor:backgroundColor?backgroundColor:'transparent'}}>
      {link ? (
        <>
          {title!=='' && title !== undefined ? 
          <NavLink to={link} className="category_title">
            <Typography variant="h2" sx={{fontSize:"20px"}}>{title}</Typography>
          </NavLink> 
          : null}
          <NavLink to={link}>
            {icon !== '' && <img src={icon} alt="" width={35} height={35} loading="lazy" />}
            {text}
            {rightImage && <img src={rightImage} width={35} height={35} alt="" className="catRightImage" loading="lazy" />}
          </NavLink>
        </>
      ) : (
        <>
          <span><img src={icon} width={35} height={35} alt="" loading="lazy" /></span>
          {text}
        </>
      )}
    </Grid>
  );
}

export default KpiBoxes;
