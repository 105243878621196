import React, { useEffect, useState } from "react";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import OfferNotificationBar from "../Common/OfferNotificationBar";
import Translations from "../Common/Translations";
import { Typography, Container, Grid } from "@mui/material";
import BlogItem from "./BlogItem";
import BlogProduct from "./BlogProduct";
import { useDispatch, useSelector } from "react-redux";
import { blogsList } from "../../features/common/commonSlice";
import Pagination from "../Common/Pagination";

function BlogList() {
  const dispatch = useDispatch();
  const { blogs, categories } = useSelector((state) => state?.common);
  const [page, setPage] = useState(1);
  //const [limit, setLimit] = useState(12);
  const [total, setTotal] = useState(0);
  const [lastPage, setLastPage] = useState(0);

  const fetchData = (page) => {
    (async () => {
      try {
        dispatch(blogsList(page)).unwrap();
      } catch (error) {}
    })();
  };

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (blogs?.data?.blogs?.data?.length > 0) {
      setPage(blogs?.data?.blogs?.current_page);
      //setLimit(blogs?.data?.blogs?.per_page);
      setTotal(blogs?.data?.blogs?.total);
      setLastPage(blogs?.data?.blogs?.last_page);
    }else{
      setPage(blogs?.data?.blogs?.current_page);
      //setLimit(blogs?.data?.blogs?.per_page);
      setTotal(blogs?.data?.blogs?.total);
      setLastPage(blogs?.data?.blogs?.last_page);
    }
  }, [blogs?.data?.blogs]);

  useEffect(() => {
    fetchData(page);
  }, [dispatch]);

  const handlePageChange = (value) => {
    setPage(value);
    fetchData(value);
  };

  return (
    <>
      <Header />
      <OfferNotificationBar />
      <section className="blog_welcome" style={{ background: "#f6faff" }}>
        <Container maxWidth={"xl"}>
          <Grid container>
            <Grid>
              <Typography variant="h1">
                <Translations text="Welcome to the Pharmacy Premium Blog" />
              </Typography>
              <Typography>
                <Translations text="Here at Pharmacy Premium, we aim to provide you with more than just the convenience of acquiring your needed medications. Our blog is dedicated to various aspects of health conditions, medications, their mechanisms of action, possible interactions, and notable medical updates that we believe will pique your interest. Explore our content organized by categories or browse the comprehensive collection to stay informed and expand your knowledge." />
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </section>
      <section>
        <Container maxWidth={"xl"}>
          {blogs?.data?.latest_blogs?.length > 0 && (
            <Grid className="new_posts">
              <Grid>
                <Typography variant="h2">
                  <Translations text="New Posts" />
                </Typography>
              </Grid>
              <div className="new_post_box">
                {blogs?.data?.latest_blogs?.map((blog, index) => {
                  return (
                    <BlogItem
                      image={blog?.banner_image}
                      title={blog?.title}
                      description={blog?.content}
                      link={`/blog/${blog?.slug}`}
                      length={150}
                      key={`latestBlog${index}`}
                    />
                  );
                })}
              </div>
            </Grid>
          )}
          <BlogProduct products={categories?.data} perView={3} />
          {blogs?.data?.blogs?.data?.length > 0 && (
            <Grid container className="new_posts">
              <Grid item xs={12}>
                <Typography variant="h2">
                  <Translations text="Show all articles" />
                </Typography>
              </Grid>
              <div className="new_post_box blog_post_grid">
                {blogs?.data?.blogs?.data?.map((blog, index) => {
                  return (
                    <BlogItem
                      image={blog?.banner_image}
                      title={blog?.title}
                      description={blog?.content}
                      link={`/blog/${blog?.slug}`}
                      length={150}
                      key={`latestBlog${index}`}
                    />
                  );
                })}
              </div>
              {lastPage && total>0  && (
                <Pagination
                  count={lastPage}
                  page={page}
                  handleChange={handlePageChange}
                />
              )}
            </Grid>
          )}
        </Container>
      </section>
      <Footer />
    </>
  );
}

export default BlogList;
