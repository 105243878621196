import React from "react";
import {Box, Grid, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";

function SliderBoxes(props) {
  const { text, icon, rightImage, link, backgroundColor, title, number, width, height } = props;

  return (
    <Grid className="ssbx" sx={{ backgroundColor:backgroundColor?backgroundColor:'transparent'}}>
      {link ? (
        <>
          {/* {title!=='' && title !== undefined ? 
          <NavLink to={link} className="">
            <Typography variant="h2">{title}</Typography>
          </NavLink> 
          : null} */}
          <NavLink to={link}>
            {icon !== '' && <img src={icon} alt="" width={width} height={height} loading="lazy" />}
            <Box className="category_nameBtn">
            <Typography variant="h3">{title}</Typography>
            <Typography className="shopnowBtn" variant="button">{text} 👉</Typography>
            </Box>
            {rightImage && <img src={rightImage} alt="" className="" width={width} height={height} loading="lazy" />}
          </NavLink>
        </>
      ) : (
        <>
          <img src={icon} alt="" width={width} height={height} loading="lazy" />
          <Box className="step_number">{number}</Box>
          <Typography variant="h2">{title}</Typography>
          <Typography>{text}</Typography>
        </>
      )}
    </Grid>
  );
}

export default SliderBoxes;
