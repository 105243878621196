import React, { useState, useEffect } from "react";
import { 
  Container,Box, Typography } from "@mui/material";
import Translations from "./Translations";
// import { Icon } from "@iconify/react";
import {getActivePaymentMethod } from "../../features/product/productApi";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";

function OfferNotificationBar() {
  const [free_shipping, setFreeShipping] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);

  const [sliderRef, productInstanceRef] = useKeenSlider({
    breakpoints: {
      "(min-width: 300px)": {
        slides: { perView: 1, spacing: 10 },
      },
      "(min-width: 768px)": {
        slides: { perView: 1, spacing: 10 },
      },
      "(min-width: 992px)": {
        slides: { perView: 2, spacing: 10 },
      },
      "(min-width: 1200px)": {
        slides: { perView: 2, spacing: 20 },
      },
    },
    initial: 0,
    loop: true,
    mode: "snap",
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
  });

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1200);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    (async () => {
      const paymentData = await getActivePaymentMethod();
      if (paymentData?.status) {
        for (const payment of paymentData?.data) {
          if (payment?.key === "free_shipping") {
            setFreeShipping(payment?.value);
          }
        }
      }
    })();
  }, []);

  return (
    <Box className="top_bar">
      <Container maxWidth={"xl"} sx={{
        background: "#1C407F"
      }}>
        {isMobile ? (
          <>
            <div ref={sliderRef} className="keen-slider">
              <div className="keen-slider__slide">
                <Typography sx={{ display: 'inline-flex' }}>
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.6667 2H0.666687V10.6667H10.6667V2Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M10.6667 5.33371H13.3334L15.3334 7.33371V10.667H10.6667V5.33371Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M3.66667 13.9996C4.58714 13.9996 5.33333 13.2534 5.33333 12.333C5.33333 11.4125 4.58714 10.6663 3.66667 10.6663C2.74619 10.6663 2 11.4125 2 12.333C2 13.2534 2.74619 13.9996 3.66667 13.9996Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M12.3334 13.9996C13.2538 13.9996 14 13.2534 14 12.333C14 11.4125 13.2538 10.6663 12.3334 10.6663C11.4129 10.6663 10.6667 11.4125 10.6667 12.333C10.6667 13.2534 11.4129 13.9996 12.3334 13.9996Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <b>
                    <Translations text="Free Shipping" /> &nbsp;
                  </b> {'  '}
                  <Translations text="on order over" />{'  '}&nbsp;
                  <b>{free_shipping} €</b>{'  '}
                </Typography>
              </div>
              <div className="keen-slider__slide">
                <Typography sx={{ display: 'inline-flex' }}>
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.99998 1.33371L10.06 5.50704L14.6666 6.18038L11.3333 9.42704L12.12 14.0137L7.99998 11.847L3.87998 14.0137L4.66665 9.42704L1.33331 6.18038L5.93998 5.50704L7.99998 1.33371Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <b>
                    3000+
                  </b>{'  '}&nbsp;
                  <Translations text="Satisfied Customers" />
                </Typography>
              </div>
              <div className="keen-slider__slide">
                <Typography sx={{ display: 'inline-flex' }}>
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.00033 14.6667C8.00033 14.6667 13.3337 12 13.3337 8.00001V3.33334L8.00033 1.33334L2.66699 3.33334V8.00001C2.66699 12 8.00033 14.6667 8.00033 14.6667Z" stroke="white" stroke-width="0.666667" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <b>
                    100%
                  </b>{'  '}&nbsp;
                  <Translations text="Discreet Packaging" />
                </Typography>
              </div>
              <div className="keen-slider__slide">
                <Typography sx={{ display: 'inline-flex' }}>
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_57_40)">
                      <path d="M8.00004 14.6667C11.6819 14.6667 14.6667 11.6819 14.6667 8C14.6667 4.3181 11.6819 1.33333 8.00004 1.33333C4.31814 1.33333 1.33337 4.3181 1.33337 8C1.33337 11.6819 4.31814 14.6667 8.00004 14.6667Z" stroke="white" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M8 4V8L10.6667 9.33333" stroke="white" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
                    </g>
                    <defs>
                      <clipPath id="clip0_57_40">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <b>
                    24-48 
                  </b>{'  '}&nbsp;
                  <Translations text="Hour Delivery" />
                </Typography>
              </div>
            </div>
            {loaded && productInstanceRef.current && (
              <>
                <Arrow
                  left
                  onClick={(e) =>
                    e.stopPropagation() || productInstanceRef.current?.prev()
                  }
                  disabled={currentSlide === 0}
                />
                <Arrow
                  onClick={(e) =>
                    e.stopPropagation() || productInstanceRef.current?.next()
                  }
                  disabled={
                    currentSlide ===
                    productInstanceRef.current.track.details.slides.length - 1
                  }
                />
              </>
            )}
          </>
        ) : (
          <>
            <Typography sx={{ display: 'inline-flex' }}>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.6667 2H0.666687V10.6667H10.6667V2Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M10.6667 5.33371H13.3334L15.3334 7.33371V10.667H10.6667V5.33371Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M3.66667 13.9996C4.58714 13.9996 5.33333 13.2534 5.33333 12.333C5.33333 11.4125 4.58714 10.6663 3.66667 10.6663C2.74619 10.6663 2 11.4125 2 12.333C2 13.2534 2.74619 13.9996 3.66667 13.9996Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M12.3334 13.9996C13.2538 13.9996 14 13.2534 14 12.333C14 11.4125 13.2538 10.6663 12.3334 10.6663C11.4129 10.6663 10.6667 11.4125 10.6667 12.333C10.6667 13.2534 11.4129 13.9996 12.3334 13.9996Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <b>
                <Translations text="Free Shipping" /> &nbsp;
              </b> {'  '}
              <Translations text="on order over" />{'  '}&nbsp;
              <b>{free_shipping} €</b>{'  '}
            </Typography>
            <Typography sx={{ display: 'inline-flex' }}>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.99998 1.33371L10.06 5.50704L14.6666 6.18038L11.3333 9.42704L12.12 14.0137L7.99998 11.847L3.87998 14.0137L4.66665 9.42704L1.33331 6.18038L5.93998 5.50704L7.99998 1.33371Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <b>
                3000+
              </b>{'  '}&nbsp;
              <Translations text="Satisfied Customers" />
            </Typography>
            <Typography sx={{ display: 'inline-flex' }}>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.00033 14.6667C8.00033 14.6667 13.3337 12 13.3337 8.00001V3.33334L8.00033 1.33334L2.66699 3.33334V8.00001C2.66699 12 8.00033 14.6667 8.00033 14.6667Z" stroke="white" stroke-width="0.666667" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <b>
                100%
              </b>{'  '}&nbsp;
              <Translations text="Discreet Packaging" />
            </Typography>
            <Typography sx={{ display: 'inline-flex' }}>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_57_40)">
                  <path d="M8.00004 14.6667C11.6819 14.6667 14.6667 11.6819 14.6667 8C14.6667 4.3181 11.6819 1.33333 8.00004 1.33333C4.31814 1.33333 1.33337 4.3181 1.33337 8C1.33337 11.6819 4.31814 14.6667 8.00004 14.6667Z" stroke="white" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M8 4V8L10.6667 9.33333" stroke="white" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
                </g>
                <defs>
                  <clipPath id="clip0_57_40">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <b>
                24-48
              </b>{'  '}&nbsp;
              <Translations text="Hour Delivery" />
            </Typography>
          </>
        )}
      </Container>
    </Box>
  );
}

function Arrow(props) {
  const disabled = props.disabled ? " arrow--disabled" : "";
  return (
    <svg
      onClick={props.onClick}
      className={`arrow ${props.left ? "arrow--left" : "arrow--right"} ${disabled}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      {props.left && (
        <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
      )}
      {!props.left && (
        <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
      )}
    </svg>
  );
}

export default OfferNotificationBar;
