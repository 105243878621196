import format from 'date-fns/format'
import CryptoJS from 'crypto-js'

export const capitalize = (str) => {
    return str.replace(/-/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
};
  
export const getDataFromLocalStorage = (key) => {
    const signupData = window.localStorage.getItem(key)
    const allData = JSON.parse(signupData)

    return allData 
};

export const getUserToken = (key) => {
    const signupData = window.localStorage.getItem(key)
    const allData = JSON.parse(signupData)
    const userData = allData?.user
    const userToken = userData ? JSON.parse(allData?.user) : ''

    return userToken 
};

export const sleep = (delay = 0) => {
    return new Promise(resolve => {
      setTimeout(resolve, delay)
    })
}

export const cartTotal = (cart)=>{
    const total = cart?.reduce(
        (accumulator, current) => accumulator + (current.quantity* current?.productPrice) + ((current?.prescriptionFee*1) || 0),
        0
      );
    return total
}

export const cartTotalPrescriptionFee = (cart)=>{
    const total = cart?.reduce(
        (accumulator, current) => accumulator + ((current?.prescriptionFee*1) || 0),
        0
      );
    return total
}
export const formatDate = (date, formatType)=>{
    return format(new Date(date), formatType)
}
export const getInitalsFromName = (name) => {
    const userName = name?.split(' ')
    const initials = `${userName?.[0]?.substring(0, 1)?.toUpperCase()}${userName?.[1] ? userName?.[1]?.substring(0, 1)?.toUpperCase() : ''}`
  
    return initials
}

export const encryptData = (data) => {
    return CryptoJS.AES.encrypt(data, 'EncodePharmaKeyABC#$%').toString()
}
  
export const decryptData = (data) => {
    let decryptedData = null
    if (data !== null) {
        const bytes = CryptoJS.AES.decrypt(data, 'EncodePharmaKeyABC#$%')
        decryptedData = bytes.toString(CryptoJS.enc.Utf8)
    }
    return decryptedData
}