import {
  ArrowBackIos,
  ArrowForwardIos,
  NavigateNext,
} from "@mui/icons-material";
import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import {
  fetchProductDetail,
  questionsByCategory,
  addToCart,
} from "../../features/product/productSlice";
import QuestionnaireModal from "../Cart/AddToCartFaq";
import CartDrawer from "../Cart/CartDrawer";
import ThankYouPage from "../Cart/CartThankyou";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import OfferNotificationBar from "../Common/OfferNotificationBar";
import SomethingWentWrong from "../Common/error";
import "keen-slider/keen-slider.min.css";
import { useKeenSlider } from "keen-slider/react";
import ProductItem from "./ProductItem";
import { toast } from "react-hot-toast";

const ProductDetail = () => {
  const { productSlug } = useParams();
  const { t } = useTranslation();
  const [isInfoVisible, setIsInfoVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const showThankYouPage = location.state?.showThankYouPage;
  //const showDrawer = location.state?.showDrawer;

  const [showDrawer, setShowDrawer] = useState(false);
  const dispatch = useDispatch();
  const { productDetails, status, questions } = useSelector(
    (state) => state.product
  );
  const { userData } = useSelector((state) => state.user || "");

  const product = productDetails?.data?.product;
  const relatedProducts = productDetails?.data?.relatedProduct;
  const productImages = product?.images || [];
  const variants = productDetails?.data?.product?.variants || [];
  const product_info = product?.product_info || [];
  const bullet = product?.bullet || [];

  const [variantsState, setVariantsState] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [product])
  
  useEffect(() => {
    if (productSlug) {
      setLoading(true)
      try {
        dispatch(fetchProductDetail(productSlug));
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }
  }, [dispatch, productSlug]);

  useEffect(() => {
    if (product?.question_category_id) {
      setLoading(true)
      try {
        dispatch(questionsByCategory(product?.question_category_id)).unwrap();
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }
  }, [product?.question_category_id, dispatch]);

  useEffect(() => {
    if (showThankYouPage) {
      handleModalClose();
      const getCartData = window.localStorage.getItem("cartData");
      addItemToCart(getCartData);
    }
  }, [showThankYouPage]);

  const addItemToCart = async (getCartData) => {
    if (userData?.data?.id) {
      try {
        await dispatch(addToCart(getCartData)).unwrap();
      } catch (error) {}
    }
  };

  useEffect(()=>{
    if(variants?.length > 0){
      for(const variant of variants){
        setVariantsState(prevState => ({
          ...prevState,
          [`${variant?.strength}${variant?.quantity}`]: false
        }))
      }
    }
  },[variants])

  const handleToggleInfo = () => {
    setIsInfoVisible(!isInfoVisible);
  };

  const [imageIndex, setImageIndex] = useState(0);
  const [selectedQuantity, setSelectedQuantity] = useState("");
  const [selectedStrength, setSelectedStrength] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [productObject, setProductObject] = useState("");
  const [productPrice, setProductPrice] = useState("");

  const handlePrevImage = () => {
    setImageIndex((prev) =>
      prev === 0 ? productDetails?.data?.product?.images?.length - 1 : prev - 1
    );
  };

  const handleNextImage = () => {
    setImageIndex((prev) =>
      prev === productDetails?.data?.product?.images?.length - 1 ? 0 : prev + 1
    );
  };

  const handleModalOpen = () => {
    const getCartData = window.localStorage.getItem("cartData");
    let r = 0;
    let answers = "";
    const catId = productDetails?.data?.product?.category?.parent?.id
      ? productDetails?.data?.product?.category?.parent?.id
      : productDetails?.data?.product?.category?.id;
    const prod = {
      productId: productDetails?.data?.product?.id,
      productName: productDetails?.data?.product?.name,
      prescriptionFee: productDetails?.data?.product?.prescription_fee,
      image: productDetails?.data?.product?.images?.[0]?.file_path,
      categoryId: catId,
      questionCategoryId: productDetails?.data?.product?.question_category_id,
      subCategoryId: productDetails?.data?.product?.category?.id,
      quantity: quantity,
      selectedQuantity: selectedQuantity,
      selectedStrength: selectedStrength,
      productPrice: productPrice,
      productPath: location?.pathname
    };
    if (getCartData) {
      const cartItem = JSON.parse(getCartData);
      if (cartItem?.length > 0) {
        const updatedCart = [];
        let s = 0;
        for (const item of cartItem) {
          if (
            item?.productId == productDetails?.data?.product?.id &&
            item?.selectedStrength == selectedStrength &&
            item?.selectedQuantity == selectedQuantity &&
            s == 0
          ) {
            r = 1;
            item.quantity = item?.quantity + quantity;
            s = 1;
          } else if (
            item?.questionCategoryId == productDetails?.data?.product?.question_category_id && s == 0
            // item?.categoryId == catId &&
            // item?.subCategoryId ==
            //   productDetails?.data?.product?.category?.id &&
            // s == 0
          ) {
            answers = item?.answers;
            const newItem = {
              ...prod,
              answers: answers,
            };
            updatedCart.push(newItem);
            r = 1;
            s = 1;
          }
          updatedCart.push(item);
        }
        if (r === 1) {
          window.localStorage.setItem("cartData", JSON.stringify(updatedCart));
          addItemToCart(JSON.stringify(updatedCart));
          window.dispatchEvent(new Event("cartUpdate"));
          toast.success(t("Product added to cart"));
          toggleDrawer(true);
          //navigate(location.pathname, { state: { showThankYouPage: true } });
        }
      }
    }
    if (r === 0) {
      window.localStorage.setItem("addToCartProduct", JSON.stringify(prod));
      setProductObject(prod);
      setOpenModal(true);
    }
  };
  const handleModalClose = () => setOpenModal(false);
  const handleTabChange = (event, newValue) => setTabIndex(newValue);

  const handleDeleteVariant = () => {
    setSelectedQuantity("");
    setSelectedStrength("");
    setProductPrice("");
  };

  const toggleDrawer = (newOpen) => {
    setShowDrawer(newOpen);
  };

  const handleRadioChange = async(e, type) => {
    if (type === "quantity") {
      setSelectedQuantity(e.target.value);
      getSelectedVariantPrice(e.target.value, selectedStrength);
      await checkDisabled(e.target.value, selectedStrength, 'quantity')
    }
    if (type === "strength") {
      setSelectedStrength(e.target.value);
      getSelectedVariantPrice(selectedQuantity, e.target.value);
      await checkDisabled(selectedQuantity, e.target.value, 'strength');
    }
    
  };

  const getSelectedVariantPrice = (quan, stren) => {
    const selectedVariant = variants?.find(
      (variant) =>
        variant?.quantity.toString() === quan && variant?.strength === stren
    );
    setProductPrice(
      selectedVariant ? selectedVariant?.amount : product?.product_price
    );
  };

  const handleQuantity = (quantity, type) => {
    if (type === "plus") {
      setQuantity(quantity + 1);
    } else {
      setQuantity(quantity - 1 > 1 ? quantity - 1 : 1);
    }
  };

  // Extract unique quantities and strengths
  const uniqueQuantities = Array.from(
    new Set(variants.map((v) => v?.quantity?.toString()))
  );
  const uniqueStrengths = Array.from(new Set(variants.map((v) => v?.strength)));

  const [loaded, setLoaded] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [sliderRef, instanceRef] = useKeenSlider({
    breakpoints: {
      "(min-width: 300px)": {
        slides: { perView: 1, spacing: 0 },
      },
      "(min-width: 992px)": {
        slides: { perView: 2, spacing: 10 },
      },
      "(min-width: 1200px)": {
        slides: { perView: 3, spacing: 20 },
      },
    },
    loop: true,
    mode: "free",
    slideChanged(slider) {
      setCurrentSlide(slider?.track?.details?.rel);
    },
    created() {
      setLoaded(true);
    },
  });

  function Arrow(props) {
    const disabled = props?.disabled ? " arrow--disabled" : "";
    return (
      <svg
        onClick={props?.onClick}
        className={`arrow ${
          props?.left ? "arrow--left" : "arrow--right"
        } ${disabled}`}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        {props?.left && (
          <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
        )}
        {!props?.left && (
          <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
        )}
      </svg>
    );
  }

  const checkDisabled = async(quantity, strength, type) => {
    if(variants?.length > 0){
      for(const variant of variants){
        let st = false;
        //if(type === 'quantity'){
          if(variant?.quantity === quantity && variant?.amount <=0){
            st = true
          }
        //}
        //if(type === 'strength'){
          if(variant?.strength === strength && variant?.amount <=0){
            st = true
          }
        //}
        setVariantsState(prevState => ({
          ...prevState,
          [`${variant?.strength}${variant?.quantity}`]: st
        }))
      }
    }
  }

  return (
    <>
      {loading ? (
        <Box className="progressOverlay"><div className="progressBx"><CircularProgress /></div></Box>
      ) : status === "failed" ? (
        <SomethingWentWrong />
      ) : showThankYouPage ? (
        <ThankYouPage />
      ) : (
        <div>
          <Header />
          <OfferNotificationBar />
          <section
            className="pd_slide_content"
            style={{ background: "#f6faff", padding: "25px 0 0" }}
          >
            <Container>
              {/* Breadcrumb */}
              <Breadcrumbs
                className="breadcrumbs"
                separator={<NavigateNext fontSize="small" />}
                aria-label="breadcrumb"
                sx={{ mb: 4 }}
              >
                {/* <Link underline="hover" color="inherit" href="">
                  {t("home")}
                </Link> */}
                {product?.category?.parent?.name && <Link
                  underline="hover"
                  color="inherit"
                  href={`/product-category/${product?.category?.parent?.slug}`}
                >
                  {product?.category?.parent?.name}
                </Link>}
                <Link
                  underline="hover"
                  color="inherit"
                  href={product?.category?.parent?.slug !== undefined ? `/product-category/${product?.category?.parent?.slug}/${product?.category?.slug}` : `/product-category/${product?.category?.slug}/${product?.category?.id}`}
                >
                  {product?.category?.name}
                </Link>
                <Typography color="text.primary">{product?.name}</Typography>
              </Breadcrumbs>

              <Grid container spacing={3}>
                {/* Product Images */}
                <Grid item xs={12} md={6}>
                  <div className="product_detail_slider">
                    <Box
                      component="img"
                      src={
                        productImages?.length > 0
                          ? productImages[imageIndex]?.file_path
                          : ""
                      }
                      alt={product?.name}
                    />
                    {productImages?.length > 1 && (
                      <>
                        <IconButton
                          onClick={handlePrevImage}
                          sx={{
                            position: "absolute",
                            top: "50%",
                            left: "10px",
                            transform: "translateY(-50%)",
                            color: "#0f3f83",
                            padding: "8px 4px 8px 12px",
                          }}
                        >
                          <ArrowBackIos />
                        </IconButton>
                        <IconButton
                          onClick={handleNextImage}
                          sx={{
                            position: "absolute",
                            top: "50%",
                            right: "10px",
                            transform: "translateY(-50%)",
                            color: "#0f3f83",
                          }}
                        >
                          <ArrowForwardIos />
                        </IconButton>
                      </>
                    )}
                  </div>
                </Grid>

                {/* Product Details */}
                <Grid item xs={12} md={6}>
                  <div className="pd_content">
                    <Typography variant="h1">{product?.name}</Typography>
                    <Typography>
                      {t("Brand")}: {product?.brand.name}
                    </Typography>
                    <Typography dangerouslySetInnerHTML={{
                        __html: product?.short_description,
                      }} sx={{fontWeight:700}}>
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: "bold", mt: 1.3, fontSize: "18px" }}
                      dangerouslySetInnerHTML={{
                        __html: product_info?.short_description,
                      }}
                    ></Typography>
                    <Typography variant="h5" className="pd_price">
                      {t("From")} {product?.product_price} €
                    </Typography>
                    <Typography className="pd_error_box" variant="body1">
                      <Icon icon="material-symbols:error" fontSize={28} />
                      <div>
                        {t(
                          "Please select 'Strength' and 'Quantity in package'"
                        )}
                      </div>
                    </Typography>

                    {/* Quantity in package */}
                    <Typography
                      variant="subtitle1"
                      sx={{ mt: 2, mb: 0.4, color: "#000", fontWeight: "700" }}
                    >
                      {t("Quantity in package")}
                    </Typography>
                    <RadioGroup
                      className="qip_btns"
                      value={selectedQuantity}
                      onChange={(e) => handleRadioChange(e, "quantity")}
                    >
                      {uniqueQuantities?.map((quantity) => (
                        <FormControlLabel
                          className={`qip_btn ${
                            selectedQuantity === quantity ? "active" : ""
                          }`}
                          disabled={variantsState?.[`${selectedStrength}${quantity}`] || false}
                          key={quantity}
                          value={quantity}
                          control={<Radio />}
                          label={quantity}
                        />
                      ))}
                    </RadioGroup>

                    {/* Strength */}
                    <Typography
                      variant="subtitle1"
                      sx={{
                        mt: 2.2,
                        mb: 0.4,
                        color: "#000",
                        fontWeight: "700",
                      }}
                    >
                      {t("Strength")}
                    </Typography>
                    <RadioGroup
                      className="qip_btns"
                      value={selectedStrength}
                      onChange={(e) => handleRadioChange(e, "strength")}
                      sx={{ mb: 2.5 }}
                    >
                      {uniqueStrengths?.map((strength) => (
                        <FormControlLabel
                          className={`qip_btn ${
                            selectedStrength === strength ? "active" : ""
                          }`}
                          key={strength}
                          disabled={variantsState?.[`${strength}${selectedQuantity}`] || false}
                          value={strength}
                          control={<Radio  />}
                          label={strength}
                        />
                      ))}
                    </RadioGroup>

                    {selectedQuantity && selectedStrength ? (
                      <>
                        <Button
                          className="delete_btn"
                          onClick={handleDeleteVariant}
                        >
                          {t("Delete")}
                        </Button>

                        <Typography className="pd_total_price" variant="h5">
                          {productPrice ? (productPrice * quantity).toFixed(2) : ""}{" "}€
                        </Typography>
                      </>
                    ) : (
                      ""
                    )}

                    {/* Quantity Selection & Add to Cart Button */}
                    <Box className="pd_qty_row">
                      <div className="pd_qty">
                        <Icon
                          fontSize={28}
                          icon="ic:baseline-minus"
                          onClick={() => handleQuantity(quantity, "minus")}
                        />
                        <TextField
                          className="pd_qty_fld"
                          value={quantity}
                          onChange={(e) =>
                            setQuantity(parseInt(e.target.value) || 1)
                          }
                        />
                        <Icon
                          fontSize={28}
                          icon="ic:baseline-plus"
                          onClick={() => handleQuantity(quantity, "plus")}
                        />
                      </div>
                      <Button
                        className="button1"
                        variant="contained"
                        onClick={handleModalOpen}
                        disabled={!selectedQuantity || !selectedStrength}
                      >
                        {t("Add to Cart")}
                        <Icon icon="fa-solid:shopping-basket" fontSize={20} />
                      </Button>
                    </Box>

                    {/* Additional Information */}
                    <Typography
                      variant="body1"
                      dangerouslySetInnerHTML={{ __html: product?.description }}
                    ></Typography>

                    {bullet.map((bullet, index) => (
                      <Typography
                        key={index}
                        variant="subtitle1"
                        sx={{ mt: 2 }}
                      >
                        {bullet}
                      </Typography>
                    ))}

                    {/* Prescription Fee */}
                    <Box className="prescription_fee">
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Link
                          onClick={handleToggleInfo}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                        >
                          <Typography variant="body2" sx={{ mr: 1 }}>
                            {t("Prescription fee")}:
                          </Typography>
                          <Typography variant="body2" sx={{ mr: 1 }}>
                            {product?.prescription_fee}{" "}€
                          </Typography>
                        </Link>
                      </Box>

                      {/* Toggleable Information Box */}
                      {isInfoVisible && (
                        <Box
                          sx={{
                            p: 2.5,
                            bgcolor: "#DCF0FF",
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{ fontSize: "14px", lineHeight: "1.66" }}
                          >
                            {t(
                              "An online prescription is required to purchase prescription drugs. Our platform allows qualified healthcare professionals to verify your medical information and issue a valid prescription."
                            )}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </section>
          {/* Product Info Tabs */}
          <section
            className="pdtbs_content"
            style={{ background: "#eef7fe", padding: "45px 0" }}
          >
            <Container maxWidth={"xl"}>
              <Grid container>
                <Grid item xs={12} sm={12} md={3}>
                  <Typography className="pdtbs_title" variant="h4">
                    Product information
                  </Typography>

                  <Tabs
                    orientation="vertical"
                    value={tabIndex}
                    onChange={handleTabChange}
                    className="verticalStaticPageTabs"
                    aria-label="Product information"
                  >
                    <Tab label={t("Product description")} />
                    <Tab label={t("Dosage")} />
                    <Tab label={t("Side effects")} />
                    <Tab label={t("Ingredients")} />
                    <Tab label={t("Pill Information")} />
                  </Tabs>
                </Grid>
                <Grid item xs={12} sm={12} md={9}>
                  <div className="pdPageContent">
                    {tabIndex === 0 && (
                      <Typography
                        variant="body1"
                        sx={{ mb: 2 }}
                        dangerouslySetInnerHTML={{
                          __html: product_info?.product_description,
                        }}
                      ></Typography>
                    )}
                    {tabIndex === 1 && (
                      <Typography
                        variant="body1"
                        sx={{ mb: 2 }}
                        dangerouslySetInnerHTML={{
                          __html: product_info?.dosage,
                        }}
                      ></Typography>
                    )}
                    {tabIndex === 2 && (
                      <Typography
                        variant="body1"
                        sx={{ mb: 2 }}
                        dangerouslySetInnerHTML={{
                          __html: product_info?.side_effects,
                        }}
                      ></Typography>
                    )}
                    {tabIndex === 3 && (
                      <Typography
                        variant="body1"
                        sx={{ mb: 2 }}
                        dangerouslySetInnerHTML={{
                          __html: product_info?.ingredients,
                        }}
                      ></Typography>
                    )}
                    {tabIndex === 4 && (
                      <Typography
                        variant="body1"
                        sx={{ mb: 2 }}
                        dangerouslySetInnerHTML={{ __html: product_info?.pil }}
                      ></Typography>
                    )}
                  </div>
                </Grid>
              </Grid>
            </Container>
          </section>

          {/* Related Products */}
          <section className="related_products">
            <Container maxWidth={"xl"}>
              <Box>
                <Typography variant="h2">
                  {t("Show more in {{category}}", {
                    category: product?.category?.parent?.name,
                  })}
                </Typography>
                <div className="navigation-wrapper">
                  <div ref={sliderRef} className="keen-slider">
                    {relatedProducts?.map((product, index) => (
                      <div
                        className={`keen-slider__slide number-slide${index}`}
                        key={`productsimilar${index}`}
                      >
                        <ProductItem
                          product={product}
                          showBrand={false}
                          showPrice={false}
                          productClass="product_bx"
                          slug={product.slug}
                          link={`/shop/${product?.category?.parent?.slug}/${product?.category?.slug}/${product?.slug}`}
                        />
                      </div>
                    ))}
                    {relatedProducts?.length > 3 &&
                      loaded &&
                      instanceRef?.current && (
                        <>
                          <Arrow
                            left
                            onClick={(e) =>
                              e.stopPropagation() ||
                              instanceRef?.current?.prev()
                            }
                            disabled={currentSlide === 0}
                          />

                          <Arrow
                            onClick={(e) =>
                              e.stopPropagation() ||
                              instanceRef?.current?.next()
                            }
                            disabled={
                              currentSlide ===
                              instanceRef?.current?.track?.details?.slides
                                ?.length -
                                1
                            }
                          />
                        </>
                      )}
                  </div>
                  {loaded && instanceRef?.current && (
                    <div className="ksDots">
                      {[
                        ...Array(
                          instanceRef?.current?.track?.details?.slides?.length
                        ).keys(),
                      ].map((idx) => {
                        return (
                          <button
                            key={idx}
                            onClick={() => {
                              instanceRef?.current?.moveToIdx(idx);
                            }}
                            className={
                              "ksDot" + (currentSlide === idx ? " active" : "")
                            }
                          ></button>
                        );
                      })}
                    </div>
                  )}
                </div>
              </Box>
            </Container>
          </section>
          <Footer />
          {showDrawer && (
            <CartDrawer
              showDrawer={showDrawer}
              toggleDrawer={toggleDrawer}
            ></CartDrawer>
          )}
          {openModal && (
            <QuestionnaireModal
              open={openModal}
              onClose={handleModalClose}
              productObject={productObject}
              questions={questions}
            />
          )}
        </div>
      )}
    </>
  );
};

export default ProductDetail;
