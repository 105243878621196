import { Icon } from "@iconify/react";
import { NavigateNext } from '@mui/icons-material';
import { Box, Breadcrumbs, CircularProgress, Container, Grid, Link, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import { fetchProductList } from '../../features/product/productSlice';
import { capitalize } from "../../utils/util";
import Footer from '../Common/Footer';
import Header from '../Common/Header';
import OfferNotificationBar from '../Common/OfferNotificationBar';
import Pagination from "../Common/Pagination";
import ProductItem from './ProductItem';

const ProductListing = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.product);
  const { categorySlug, subCategorySlug } = useParams();

  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [lastPage, setLastPage] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const fetchData = (categorySlug, subCategorySlug) => {
    (async () => {
      setLoading(true)
      try {
        let slug = subCategorySlug
        if(!isNaN(parseInt(subCategorySlug))){
          slug = categorySlug
        }
        dispatch(fetchProductList(slug)).unwrap();
        setLoading(false)
      } catch (error) {
        setLoading(false) 
      }
    })();
  };

  useEffect(() => {
    if (products?.data?.products?.data?.length > 0) {
      setPage(products?.data?.products?.current_page);
      setTotal(products?.data?.products?.total);
      setLastPage(products?.data?.products?.last_page);
    } else {
      setPage(products?.data?.products?.current_page);
      setTotal(products?.data?.products?.total);
      setLastPage(products?.data?.products?.last_page);
    }
  }, [products?.data?.products]);

  useEffect(() => {
    fetchData(categorySlug, subCategorySlug);
  }, [dispatch,categorySlug, subCategorySlug]);

  const handlePageChange = (value) => {
    setPage(value);
    fetchData(value);
  };

  return (
    <>
      {loading ? <Box className="progressOverlay"><div className="progressBx"><CircularProgress /></div></Box> :
        <div>
          <Header />
          <OfferNotificationBar />
          <section className='productlist_wrap'>
            <Container maxWidth={"xl"}>
              {/* Category Name */}
              <Typography variant="h1" align="center" gutterBottom>
                {!isNaN(parseInt(subCategorySlug)) ? capitalize(categorySlug?.replace("-", " ")) : capitalize(subCategorySlug?.replace("-", " "))}
              </Typography>

              {/* Breadcrumb */}
              <Breadcrumbs
                className='breadcrumbs'
                separator={<NavigateNext fontSize="small" />}
                aria-label={t("breadcrumb")}
                sx={{ justifyContent: 'center', display: 'flex', mb: 3 }}
              >
                <Link underline="hover" color="inherit" href="/">
                  <Icon icon="carbon:home" fontSize={18} />
                </Link>
                <Link underline="hover" color="inherit" href={`/product-category/${categorySlug}`}>
                  {capitalize(categorySlug.replace("-", " "))}
                </Link>
                {isNaN(parseInt(subCategorySlug)) && (
                  <Typography color="#fbaa1a">
                    {capitalize(subCategorySlug.replace("-", " "))}
                  </Typography>
                )}
              </Breadcrumbs>

              {/* Product Grid */}
              {products?.data?.data?.length > 0 && (
                <Grid className="product_row">
                  {products?.data?.data.map((product, index) => (
                    <Grid item key={product.id} className="product_item">
                      <ProductItem product={product} showBrand={true} showPrice={true} productClass="product_bx" slug={product.slug} link={`/shop/${categorySlug}/${subCategorySlug}/${product?.slug}`} />
                    </Grid>
                  ))}
                </Grid>
              )}


              {/* Category Description */}
              <Box sx={{ mt: 3, mb: 2 }}>
                <Typography variant="body1">
                  {products?.data?.data?.[0]?.category?.description}
                </Typography>
              </Box>
              {lastPage && total > 0 && (
                <Pagination
                  count={lastPage}
                  page={page}
                  handleChange={handlePageChange}
                />
              )}
            </Container>
          </section>
          <Footer />
        </div>
      }
    </>
  );
};

export default ProductListing;